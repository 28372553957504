import React, { useContext, useEffect, useState } from 'react';
import c from './library.module.scss';
import WidgetIcon from '../../widget-library/WidgetIcon';
import preview from './preview.png';
import lamp from './lamp.svg';
import {
  ExternalLink,
  LoadingIndicator,
  Tabs,
  TABS_VARIANT,
} from '@monash/portal-react';
import Loans from './Loans';
import Requests from './Requests';
import Fees from './Fees';
import { APIContext, useSessionStorage } from '@monash/portal-frontend-common';
import { hasAllErrors, hasAnyErrors } from './utils';
import WIDGET_ERROR_MESSAGE from '../../widget-container/widget-error/WidgetErrorMessage';

const Library = ({ onSelectedPage, typeId, setError }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { getLibraryData } = useContext(APIContext);
  const [libraryData, setLibraryData] = useSessionStorage(
    `widgetType:${typeId}`
  );
  const [loading, setLoading] = useState(
    !libraryData || hasAnyErrors(libraryData)
  );
  const TABS_ID_PREFIX = 'library-widget';

  useEffect(() => {
    // fetch data if there's no data or if there are any errors
    if (onSelectedPage && loading) {
      getLibraryData()
        .then((r) => {
          // if there are error flags for each of loans, requests, and fees, show error message
          if (hasAllErrors(r)) {
            setError(WIDGET_ERROR_MESSAGE.GENERIC);
          } else {
            setLibraryData(r);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.warn(
            '[getLibraryData]: api call error, failed to get library data.',
            error
          );
          setError(WIDGET_ERROR_MESSAGE.GENERIC);
        });
    }
  }, [onSelectedPage]);

  // if data is loading, show loading indicator
  if (loading) {
    return (
      <div className={c.centreContent}>
        <LoadingIndicator />
      </div>
    );
  }

  // otherwise, parse the data to see if there exists some library data that we can show...
  const loansData = libraryData.loans;
  const requestsData = libraryData.requests;
  const feesData = libraryData.fees;
  const errors = libraryData.errorFlags;

  // init array of data for each of the tabs
  const initTabsData = [
    {
      label: 'Loans',
      data: loansData,
      component: Loans,
      errorFlag: errors.loans,
      showCount: true,
    },
    {
      label: 'Requests',
      data: requestsData,
      component: Requests,
      errorFlag: errors.requests,
      showCount: true,
    },
    {
      label: 'Fees',
      data: feesData,
      component: Fees,
      errorFlag: errors.fees,
      showCount: false,
    },
  ];

  const tabs = [];

  // filter/parse tabs data array based on whether data exists for the tab
  for (const tab of initTabsData) {
    const label = tab.label;
    const data = tab.data;
    const component = tab.component;
    const errorFlag = tab.errorFlag;
    const showCount = tab.showCount;

    if (tab.errorFlag === 0 && data?.total_record_count > 0) {
      const parsedTabData = {
        label: showCount ? `${label} (${data.total_record_count})` : label,
        data,
        component,
        errorFlag,
      };
      tabs.push(parsedTabData);
    }
  }

  // if there's no data, show illustration for no data
  if (!tabs.length) {
    return (
      <div className={`${c.centreContent} ${c.noData}`}>
        <img src={lamp} alt="" />
        <h3>Find books, articles, journals, databases and more</h3>
        <ExternalLink
          text="Open Library"
          link="https://www.monash.edu/library"
          variant="text"
          data-tracking-event-location="library-widget"
        />
      </div>
    );
  }

  // if there is data, render the correct tabs and content
  const getContent = (tab) => {
    const data = tab.data;
    const errorFlag = tab.errorFlag;
    const Component = tab.component;

    return <Component data={data} error={errorFlag} />;
  };

  return (
    <div className={c.library}>
      <div className={c.tabsContainer}>
        <Tabs
          tabs={tabs.map((tab) => tab.label)}
          selected={selectedTabIndex}
          onChange={(i) => {
            setSelectedTabIndex(i);
          }}
          variant={TABS_VARIANT.PLAIN_UNDERLINE}
          label="My library widget tabs"
          idPrefix={TABS_ID_PREFIX}
        />
      </div>
      <div
        className={c.contentContainer}
        id={`${TABS_ID_PREFIX}-tabpanel-${selectedTabIndex}`}
        role="tabpanel"
        aria-labelledby={`${TABS_ID_PREFIX}-tab-button-${selectedTabIndex}`}
        tabIndex="0"
      >
        {getContent(tabs[selectedTabIndex])}
      </div>
    </div>
  );
};

const LibraryModule = {
  component: Library,
  name: 'My library',
  icon: WidgetIcon.Library,
  previewImage: preview,
  previewBackgroundColor: '#DCFCE7',
  users: ['Australia', 'Indonesia', 'Malaysia'],
  additionalOptions: null,
  description: 'Check your library loans, requests and fees', // REMOVE description AND UPDATE descriptionUplift to description WHEN REMOVE FEATURE FLAG
  descriptionUplift:
    'Take a look at what you’ve loaned and requested – and don’t forget your library fees.',
};

export default LibraryModule;
