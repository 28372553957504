import React, { useContext, useEffect } from 'react';
import c from './m-pass.module.scss';
import fs from 'styles/font-styles.module.scss';
import balance from './balance.svg';
import { formatAmount } from '@monash/portal-frontend-common';
import { InlineExternalLink, LoadingIndicator } from '@monash/portal-react';
import WidgetIcon from '../../widget-library/WidgetIcon';
import preview from './preview.svg';
import WIDGET_ERROR_MESSAGE from '../../widget-container/widget-error/WidgetErrorMessage';
import DataVisibilityToggle from '../../widget-container/header/header-actions/data-visibility-toggle/DataVisibilityToggle';
import { DataContext } from 'components/providers/data-provider/DataProvider';

const MPass = ({ data, onSelectedPage, setError }) => {
  const { errors, profileData } = useContext(DataContext);
  const accountBalance = formatAmount(profileData?.cardholder?.generalBalance);
  const printingBalance = formatAmount(
    profileData?.cardholder?.printingBalance,
    {
      hideEmptyValues: true,
    }
  );

  // error handling
  const mPassError =
    errors.profileData ||
    // partial errors
    profileData?.errors.find(
      (error) =>
        error.additionalDetails === 'Cardholder not found' ||
        error.additionalDetails === 'Unable to reach Transact'
    );

  useEffect(() => {
    if (onSelectedPage && mPassError) {
      setError(WIDGET_ERROR_MESSAGE.M_PASS);
    }
  }, [onSelectedPage, mPassError]);

  return (
    <div className={c.mPass}>
      <img src={balance} alt="" />
      {!data || !profileData ? (
        <LoadingIndicator />
      ) : (
        <div className={c.content}>
          <div className={c.accountBalance}>
            <div className={c.balance} onKeyDown={(e) => e.stopPropagation()}>
              <p>
                <strong>$</strong>
              </p>
              <p className={c.amount}>
                {data?.visible ? accountBalance : 'xx.xx'}
              </p>
              <InlineExternalLink
                link="https://monash-sp.transactcampus.com/eAccounts/AnonymousHome.aspx"
                aria-label="Go to balance"
                className={c.externalLink}
                size={20}
                data-tracking-event-location="mpass-widget"
              />
            </div>
          </div>

          <div className={c.additionalDetails}>
            <div
              className={c.cardNumber}
              style={{
                textAlign: printingBalance ? 'start' : 'center',
              }}
            >
              <span className={fs.label}>Student ID</span>
              <strong>
                <p>{data?.visible ? profileData?.id : 'xxxxxxxx'}</p>
              </strong>
            </div>
            {printingBalance && (
              <div className={c.printingBalance}>
                <span className={fs.label}>Print balance</span>
                <span className={c.balance}>
                  <p>
                    <strong>$</strong>
                  </p>
                  <p>
                    <strong>{data?.visible ? printingBalance : 'xx.xx'}</strong>
                  </p>
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const MPassModule = {
  component: MPass,
  name: 'M-Pass balance',
  icon: WidgetIcon.MPass,
  previewImage: preview,
  previewBackgroundColor: '#DBEAFE',
  description: 'Check your account balance', // REMOVE description AND UPDATE descriptionUplift to description WHEN REMOVE FEATURE FLAG
  descriptionUplift: 'Check your credit and top up if you need.',
  users: ['Australia', 'Monash College'],
  defaultSize: 'S',
  headerActions: [
    {
      component: DataVisibilityToggle,
      componentProps: {
        dataLabel: 'M-Pass balance',
      },
    },
  ],
  additionalOptions: [
    {
      key: 'visible',
      default: true,
      editType: null,
    },
  ],
};

export default MPassModule;
