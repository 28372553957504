import { Logo } from '@monash/portal-react';
import c from './onboarding.module.scss';
import OnboardingCarousel from 'components/ui/onboarding-carousel/OnboardingCarousel';
import {
  APIContext,
  AuthContext,
  fsUpdateDoc,
  fsDeleteField,
  ImpersonationContext,
} from '@monash/portal-frontend-common';
import { useContext, useState } from 'react';
import { LOCATION } from 'constants/locations';
import { DataContext } from 'components/providers/data-provider/DataProvider';

const Onboarding = ({ updateOnboarding }) => {
  const content = [
    {
      subTitle: 'Welcome to the',
      title: 'Student Portal',
      description: 'Your digital home at Monash',
      backgroundColor: '#1A477A',
      position: 'center',
      animation: [require('../../../assets/lotties/onboarding/welcome.json')],
    },
    {
      title: ' Keep up with classes, assessments and key dates',
      description:
        'See what you’ve got on for the day on your Upcoming page, or check your Schedule for what’s happening week by week. With links to Moodle, maps and more, you’ll have what you need at your fingertips.',
      hiddenFrom: [LOCATION.COLLEGE],
      animation: [require('../../../assets/lotties/onboarding/timetable.json')],
    },
    {
      title: 'Your portal, your style',
      description:
        'Select a theme and use our widget library to create custom pages where you can take notes, check your M-Pass balance, see your assessments and more. There are lots of combinations to choose from.',
      animation: [
        require('../../../assets/lotties/onboarding/custom01.json'),
        require('../../../assets/lotties/onboarding/custom02.json'),
        require('../../../assets/lotties/onboarding/custom03.json'),
      ],
    },
    {
      title: 'No need to dig for the important stuff …',
      description:
        'It’s all in the top-right corner. Search useful links, ask our virtual assistant (MoVA) a question, access your most-used apps, and check your course progress.',
      animation: [
        require('../../../assets/lotties/onboarding/essentials01.json'),
        require('../../../assets/lotties/onboarding/essentials02.json'),
      ],
    },
    {
      title: 'Select location',
      description:
        'It looks like you’re enrolled in units at two locations. Select your primary location and we’ll create pages for each location with helpful widgets to get you started (your primary location page will have a bit more detail on it). ',
      hiddenFrom: [LOCATION.COLLEGE],
      options: [
        {
          value: LOCATION.AUSTRALIAN,
          image: require('../../../assets/images/onboarding/university.svg'),
        },
        {
          value: LOCATION.MALAYSIAN,
          image: require('../../../assets/images/onboarding/malaysia.svg'),
        },
        {
          value: LOCATION.INDONESIAN,
          image: require('../../../assets/images/onboarding/indonesia.svg'),
        },
        {
          value: LOCATION.COLLEGE,
          image: require('../../../assets/images/onboarding/college.svg'),
        },
      ],
    },
  ];

  const { currentUser } = useContext(ImpersonationContext);
  const { addDefaultPages } = useContext(APIContext);
  const { refreshPortalPreferences } = useContext(DataContext);
  const { userDoc } = useContext(AuthContext);
  const userDocPath = `users/${currentUser.uid}`;

  // Extract locations where the user has `true`
  const locations = Object.values(LOCATION)
    .filter((loc) => {
      return currentUser.is?.[loc.KEY] === true;
    }) // Ensure it checks against true
    .map((loc) => loc);

  const createDefaultPage = (primary) => {
    const secondary = locations.filter((loc) => loc.KEY !== primary.KEY);
    const primaryPage = primary.PAGE;
    const miniPages = secondary.map((l) => l.PAGE);
    addDefaultPages(primaryPage, miniPages).then(() =>
      refreshPortalPreferences()
    );
  };

  // Filter the content if the banner is hidden for the current user
  const filteredContent = content
    .filter(
      (item) =>
        !item.hiddenFrom?.some((location) => locations.includes(location))
    )
    .filter(
      (item) => !(locations.length === 1 && item.title === 'Select location')
    )
    .map((item) => ({
      ...item,
      options: item.options?.filter((option) =>
        locations.includes(option.value)
      ),
    }));

  // Active page
  const [activePageIndex, setActivePageIndex] = useState(
    userDoc?.history?.onboardingProgress || 0
  );
  const updatePageIndex = (updateFn) => {
    // updateFn is a callback like setState((f) => ...)
    setActivePageIndex((f) => {
      const updatedIndex = updateFn(f);
      fsUpdateDoc(userDocPath, {
        'preferences.history.onboardingProgress': updatedIndex,
      });
      return updatedIndex;
    });
  };

  const completeOnboarding = () => {
    updateOnboarding(true);
    // write to fs, remove onboardingProgress field
    fsUpdateDoc(userDocPath, {
      'preferences.history.onboardingProgress': fsDeleteField(),
    });
  };

  return (
    <div
      className={`${c.onboardingContainer} ${
        activePageIndex === 0 && c.firstPage
      }`}
    >
      <div className={c.navContainer}>
        <a href="/" aria-label="home" data-tracking-event="topnav-home-logo">
          <Logo.MonashStacked />
        </a>
      </div>
      <OnboardingCarousel
        data={filteredContent}
        createDefaultPage={createDefaultPage}
        activePageIndex={activePageIndex}
        updatePageIndex={updatePageIndex}
        completeOnboarding={completeOnboarding}
      />
    </div>
  );
};
export default Onboarding;
