import React, { useContext, useRef } from 'react';
import c from './shortcuts.module.scss';
import preview from './assets/preview.png';
import empty from './assets/empty.svg';
import WidgetIcon from '../../widget-library/WidgetIcon';
import { Button, Icon } from '@monash/portal-react';
import { WidgetContext } from 'components/providers/WidgetProvider';
import IconDisplay from '../../components/icon-display/IconDisplay';
import EditShortcuts from './edit-shortcuts/EditShortcuts';

const Shortcuts = ({
  widgetId,
  inEditMode,
  data,
  updateData,
  escapeSettingActionsFocusRef,
}) => {
  const shortcuts = Array.isArray(data?.shortcuts) ? data?.shortcuts : [];

  const { editLinks, editActiveWidget, editLinksEscapeFocusRef } =
    useContext(WidgetContext);
  const shouldDisplayURL = data?.display?.find((id) => id === 'url');
  const editShortcutsButtonRef = useRef();

  return (
    <div className={c.shortCuts}>
      {editActiveWidget === widgetId ? (
        <EditShortcuts
          widgetId={widgetId}
          inEditMode={inEditMode}
          updateData={updateData}
          currentShortcuts={shortcuts}
          escapeFocusRef={
            editLinksEscapeFocusRef || escapeSettingActionsFocusRef
          }
        />
      ) : null}
      {shortcuts?.length ? (
        <div className={c.shortcutsList}>
          {shortcuts.map((shortcut, i) => (
            <a
              key={i}
              className={c.shortcut}
              href={shortcut.url}
              target="_blank"
              rel="noreferrer"
              aria-description="Opens in a new window"
              onKeyDown={(e) => e.stopPropagation()}
              data-tracking-event-location="shortcuts-widget"
            >
              {shortcut.icon ? (
                <IconDisplay icon={shortcut.icon} url={shortcut.url} />
              ) : null}
              <div className={c.content}>
                <p className={c.shortcutName}>{shortcut.name}</p>
                {shouldDisplayURL ? (
                  <p className={c.shortcutUrl}>{shortcut.url}</p>
                ) : null}
              </div>
            </a>
          ))}
        </div>
      ) : (
        <div
          className={c.emptyShortcuts}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <div className={c.graphic}>
            <img src={empty} alt="" />
            <h3>No shortcuts yet</h3>
          </div>
          <div className={c.editShortcuts}>
            <Button
              ref={editShortcutsButtonRef}
              variant="text"
              size="small"
              icon={<Icon.Pencil />}
              onClick={() => {
                editLinks(widgetId, editShortcutsButtonRef);
              }}
              mode="card"
            >
              Add/Edit shortcuts
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const ShortcutsModule = {
  component: Shortcuts,
  name: 'Shortcuts',
  icon: WidgetIcon.Shortcuts,
  previewImage: preview,
  previewBackgroundColor: '#FEE2E2',
  description: 'Save links for quick and easy access', // REMOVE description AND UPDATE descriptionUplift to description WHEN REMOVE FEATURE FLAG
  descriptionUplift: 'Keep all the links you use most – all in one place.',
  additionalOptions: [
    {
      key: 'shortcuts',
      editType: null,
      default: [],
    },
    {
      key: 'display',
      name: 'Display',
      editType: 'checkbox',
      default: [],
      options: [{ id: 'url', name: 'URL' }],
    },
    {
      key: 'name',
      name: 'Name',
      editType: 'text',
      default: 'Shortcuts',
    },
  ],
  additionalActions: [
    {
      action: 'editLinks',
      icon: <Icon.Pencil size={20} />,
      text: 'Add/Edit shortcuts',
    },
  ],
};

export default ShortcutsModule;
