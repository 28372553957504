// Local features flags
export const FEATURES = {
  DISPLAY_TIME_GRID: true,
  FORCE_MOCK_API: false,
};

// Feature flags for feature toggles
export const FEATURE_FLAGS = {
  CRITICAL_AND_NON_CRITICAL_NOTIFICATIONS: true,
  GRADES: true,
  UPDATES: false,
  MOVE_WIDGET: false,
  IMPORTANT_DATES_UPLIFT: true,
};
